const env = process.env.VUE_APP_ENV;

// if (env === 'production') {
//   envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
// } else if (env === 'staging') {
//   envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
// } else {
//   envApiUrl = `https://${process.env.VUE_APP_DOMAIN_DEV}`;
// }
export const envApiUrl = `https://${process.env.VUE_APP_DOMAIN}`;
export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
